<template>
    <div class="text-content row" >
        <input type="text" v-model="url" :placeholder="options.placeholder"
            :class="{'invalid-field' : !isUrlValid}">
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
import ValidationHelper from '@/utils/ValidationHelper.js'
export default {
    name:'VideoUrlSetter', 
    mixins:[ComponentSetterMixin],  
    components:{
        ValidationHelper
    },
    data(){
        return{
            isUrlValid: true,
            errorMessage :'URL is invalid', 
        }
    },
    computed:{
        url:{
            get(){
                return this.component[this.options.model][this.locale] || "";
            }, 
            set(value){   
                this.$store.commit('removeBackOfficeComponentValidationError',{
                    error: this.errorMessage, 
                    component: this.component
                })
                this.isUrlValid = ValidationHelper.isVideoUrlValid(value)
                if(!this.isUrlValid)
                    this.$store.commit('addBackOfficeComponentValidationError', this.errorMessage)
                this.setComponentPropertyValue(value, this.options.mode, this.locale);
            }
        },
    },
    watch:{
        'component.errors': function(value){
            let indexofBackgroundError = value.indexOf(this.errorMessage)
            indexofBackgroundError >= 0
                ? this.isUrlValid = false
                : this.isUrlValid = true        
        }
    }
}
</script>


