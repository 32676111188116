<template>
    <div class="background-setter">
        <span>{{$t(propertyName)}}</span>
        <div class="properties-section flex-container">
            <div class="background-color"  :style="{backgroundColor : background, opacity: (opacity/100)}"></div>
            <div class="property-control">
                <input type="text"  v-model="background" :class="{'invalid-field' : !isBackgroundColorValid}">
                <label for="background-input">HEX</label>
            </div>
            <div class="property-control">
                <input type="number"  min="0" max="100" v-model="opacity">
                <label for="background-opacity">{{$t('opacityTag')}}</label>
            </div>

        </div>
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
import ValidationHelper from '@/utils/ValidationHelper.js'
export default {
    name:'BackgroundSetter', 
    mixins:[ComponentSetterMixin],  
    comments:{
        ValidationHelper
    },
    data(){
        return{
            isBackgroundColorValid: true,
            errorMessage :this.$t('invalidBackgrounColorAlert')
        }
    },
    computed:{
        background:{
            get(){
                return this.component[this.options.model]
            }, 
            set(value){
                let color = '#' + value.substring(0, 7).replace('#', '')
                this.$store.commit('removeBackOfficeComponentValidationError',{error: this.errorMessage})
                this.isBackgroundColorValid = true;
                
                if(color != '' && !/^#[0-9a-f]{3}([0-9a-f]{3})?$/i.test(color)) {
                    this.isBackgroundColorValid = false;
                    this.$store.commit('addBackOfficeComponentValidationError', this.errorMessage)
                }
                this.setComponentPropertyValue(color, this.options.model)
            }
        },
        opacity: {
            get(){
                return this.component.opacity
            }, 
            set(value){
                value = value > 100 ? 100 : value
                value = value < 0 ? 0 : value
                value = Math.round(value)
                this.setComponentPropertyValue(value, 'opacity')
            }
        },
        propertyName(){
            return this.options.propertyName || "backgroundTag";
        }
    },
    mounted(){
        if(!this.opacity)
            this.opacity =100;
    }
}
</script>

<style lang="less" scoped>
.background-color {
    align-self: center;
    margin-right: 11px;
	height: 16px;
	width: 24px;
    border: 2px solid rgba(0,0,0,0.1);
    margin-bottom: 10px;
}
</style>


