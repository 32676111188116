<template>
    <div class="map-point column">
        <audio-upload :key="'audio'+component.id" :component="component" :options="audioOptions" />
        <image-upload v-for="image in component.images" :key="image.id" :component="{imageId: image}" :remove="removeImage"
                :options="imageOptions"/>
        <image-upload :component="{imageId:''}" :add="addImage" :options="imageOptions"/>
    </div>
</template>
<script>
import ImageUpload from '@/components/BackOffice/ComponentSetters/ImageUpload.vue'
import AudioUpload from '@/components/BackOffice/ComponentSetters/AudioUpload.vue'
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'

export default {
    name:'MapPointSetter', 
    mixins:[ComponentSetterMixin], 
    components:{
        AudioUpload, 
        ImageUpload
    }, 
    computed:{
        content(){
            return this.component.content[this.locale]
        }, 
        audioOptions(){
            return this.component.setters
                    .find(e => e.name === 'audio-upload')
        },
        imageOptions(){
            return {"name": "image-upload", "text":"addLayerText", "model":"imageId"}
        }
    },
    methods:{
        addImage(imageId){
            this.$store.commit('addImageIdToRoutePoint',{
                point :this.component, 
                imageId: imageId
            })
        },
        removeImage(imageId){
            this.$store.commit('removeImageIdToRoutePoint',{
                point :this.component, 
                imageId: imageId
            })
        }
    }
}
</script>
<style lang="less" scoped>

.map-point{
    min-height: 112px;
    margin-top: 8px;
    span{
        max-width: 80px;
        margin-left: 16px;
    }
    .delete-icon{
        margin-left: auto;
        margin-right: 16px;
        cursor: pointer;
    }
}

</style>

