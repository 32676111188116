<template>
    <div class="color-setter">
        <div class="properties-section flex-container">
            <div class="background-color"  :style="{backgroundColor : selectedColor}"></div>
            <div class="property-control">
                <select v-model="selectedColor">
                    <option v-for="option in colorOptions" :key="option.text"
                         :value="option.value">{{option.text}}</option>
                </select>
                <label for="image-width">{{$t('fontColorTag')}}</label>
            </div>
        </div>
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
export default {
    name:'FontColorSetter', 
    mixins:[ComponentSetterMixin],  
    data: function() {
        return{
            selected: '', 
            colorOptions:[
                    {text: this.$t('black'), value:'rgba(0,0,0,0.8)'},
                    {text: this.$t('white'), value:'rgba(255,255,255,1)'},
                    {text: this.$t('blue'), value:'rgba(58,77,253,1)'}, ]
        }
    },
    computed:{
        selectedColor:{
            get(){
                return this.component.color
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'color')
            }
        }
    }
}
</script>

<style lang="less" scoped>
.background-color {
    align-self: center;
    margin-right: 11px;
	height: 16px;
	width: 24px;
    border: 2px solid rgba(0,0,0,0.1);
    margin-bottom: 10px;
}
select{
    max-width: 75px;
}
</style>


