<template>
    <div class="background-setter">
        <span>SIZE</span>
        <div class="properties-section flex-container">
            <div class="property-control">
                <select v-model="selectedSize">
                    <option v-for="option in sizeOptions" :key="option.text"
                         :value="option.value">{{option.text}}</option>
                </select>
                <label for="image-width">{{$t('widthSizeTag')}}</label>
            </div>
            <div class="property-control" v-if="selectedSize != 12">
                <select v-model="selectedAlignment">
                    <option v-for="option in alignmentOptions" :key="option.text"
                         :value="option.value">{{option.text}}</option>
                </select>
                <label for="background-opacity">{{$t('alignmentTag')}}</label>
            </div>
        </div>
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
export default {
    name:'SizeSetter', 
    mixins:[ComponentSetterMixin],  
    data: function() {
        return{
            selected: '', 
            sizeOptions: [
                {text: this.$t('medium'), value:'8'},
                {text: this.$t('large'), value:'12'},], 
            alignmentOptions:[
                    {text: this.$t('left'), value:'left-side'},
                    {text: this.$t('center'), value:'middle'},
                    {text: this.$t('right'), value:'right-side'},]
        }
    },
    computed:{
        selectedSize:{
            get(){
                return this.component.size
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'size')
            }
        }, 
        selectedAlignment:{
            get(){
                return this.component.orientation
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'orientation')
            }
        }
    }
}
</script>

<style lang="less" scoped>

.properties-section{
    .property-control:nth-child(2)
    {
        margin-left: 8px;
        select{
            margin-left: 8px;

        }
    }
}

</style>


