import PanelLegend from '@/components/BackOffice/PanelLegend.vue'
import ImageUpload from '@/components/BackOffice/ComponentSetters/ImageUpload.vue'
import BackgroundSetter from '@/components/BackOffice/ComponentSetters/BackgroundSetter.vue'
import FontColorSetter from '@/components/BackOffice/ComponentSetters/FontColorSetter.vue'
import ImageDetails from '@/components/BackOffice/ComponentSetters/ImageDetails.vue'
import AudioUpload from '@/components/BackOffice/ComponentSetters/AudioUpload.vue'
import SizeSetter from '@/components/BackOffice/ComponentSetters/SizeSetter.vue'
import ImageSizeSetter from '@/components/BackOffice/ComponentSetters/ImageSizeSetter.vue'
import ImagesListSetter from '@/components/BackOffice/ComponentSetters/ImagesListSetter.vue'
import EssentialQuestionSetter from '@/components/BackOffice/ComponentSetters/EssentialQuestionSetter.vue'
import CheckBox from '@/components/BackOffice/ComponentSetters/CheckBox.vue'
import ListOrganizer from '@/components/BackOffice/ComponentSetters/ListOrganizer.vue'
import TitleSetter from '@/components/BackOffice/ComponentSetters/TitleSetter.vue'
import DescriptionSetter from '@/components/BackOffice/ComponentSetters/DescriptionSetter.vue'
import TextAlignmentSetter from '@/components/BackOffice/ComponentSetters/TextAlignmentSetter.vue'
import VideoUrlSetter from '@/components/BackOffice/ComponentSetters/VideoUrlSetter.vue'
import DateSetter from '@/components/BackOffice/ComponentSetters/DateSetter.vue'
import LanguageSetter from '@/components/BackOffice/ComponentSetters/LanguageSetter.vue'
import MapSetter from '@/components/BackOffice/ComponentSetters/MapSetter.vue'
import TeksListSetter from '@/components/BackOffice/ComponentSetters/TeksListSetter.vue'
import { setTimeout } from 'timers';
import {i18n} from '@/plugins/i18n.js';

export default {
    props:{
        'save-component':  Function,
    },
    data(){
        return{
            saving: false
        }
    },
    components:{
        'panel-legend':PanelLegend, 
        'image-upload': ImageUpload,
        'image-details': ImageDetails,
        'audio-upload': AudioUpload, 
        'size-setter': SizeSetter,
        'image-size-setter': ImageSizeSetter,
        'essential-question-setter': EssentialQuestionSetter,
        'check-box': CheckBox,
        'list-organizer': ListOrganizer, 
        'teks-list-setter':TeksListSetter,
        BackgroundSetter,
        TitleSetter,
        DescriptionSetter,
        FontColorSetter,
        TextAlignmentSetter,
        VideoUrlSetter, 
        DateSetter, 
        LanguageSetter,
        MapSetter,
        ImagesListSetter,
        i18n
    },
    computed:{
        component(){
            return this.$store.state.currentBackOfficeComponent
        }, 
        saveButtonText(){
            return this.saving 
                ?  this.$t('savingChangesTag')
                :  this.$t('saveChangesTag')
        }
    }, 
    methods:{
        ToggleAutoSaveInterval(){
            clearInterval(this.autoSaveInterval)
            this.autoSaveInterval =setInterval(function () {
                if(!this.$store.state.loadingStatus)
                    return
                this.saveChanges()
            }.bind(this), 15000); 
        }, 
        clearAutoSaveInterval(){
            this.saveChanges()
            clearInterval(this.autoSaveInterval)
        }, 
        saveChanges(){
            this.saving = true
            setTimeout(()=>{
                this.saveComponent()
                this.saving = false
            }, 2000)
        }
    },
    created(){
        this.ToggleAutoSaveInterval()
    },
    beforeDestroy(){
        this.$store.commit('updateLoadingStatus', true)
        this.clearAutoSaveInterval()
        this.$store.commit('updateLoadingStatus', false)
    }

}