<template>
    <div class="image-upload flex-container" :class="{'image-uploaded': model}" v-if="options">
        <template v-if="!model">
            <div class="upload-btn-wrapper">
                <texas-history-button :text="$t(options.text)" :secondary="true"/>
                <input type="file" @change="onFileChange">
            </div>
        </template>
        <template v-else>
            <img v-if="imageSource"  class="image" :src="imageSource"/>
            <span>{{imageName}}</span>
            <img :src="deleteIcon" class="delete-icon"  @click="removeImage"/>
        </template>
    </div>
</template>
<script>
import TexasHistoryButton from '@/common/TexasHistoryButton.vue'
import ImageService from '@/services/ImageService.js'
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
export default {
    name:'ImageUpload', 
    mixins:[ComponentSetterMixin],  
    components:{
       'texas-history-button': TexasHistoryButton, 
       ImageService
    },
    props:{
        add:{
            type: Function,
            required: false
        },
        remove:{
            type: Function,
            required: false
        }
    },
    data: function(){
        return{
            imageName: ''
        }
    },
    methods: {
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return; 
            if(!files[0].type.includes('image'))
            {
                this.$store.commit('addSnackBarNotification',{message: this.$t('wrongFileFormatAlert')})
                return;
            }
            let formData = new FormData();
            formData.append('file', files[0]);  
            this.imageName = files[0].name
            this.uploadImage(formData)  
        }, 
        uploadImage(data){
            this.$store.commit('updateLoadingStatus', true)
            setTimeout(()=>{
                ImageService.uploadImage(data)
                    .then((response) =>{
                        if(this.add)
                            this.add(response.data.id)
                        else
                            this.model = response.data.id
                        this.$store.commit('updateLoadingStatus', false)
                    })
                    .catch(()=> {
                        this.$store.commit('updateLoadingStatus', false);
                        this.$store.commit('addSnackBarNotification', {
                                    message: this.$t("failedToUploadImage"),
                                    dismissable: false
                                });
                    })
            }, 500)
        }, 
        getImageName(){
            if(!this.model)
                return
            ImageService.getOriginalName(this.model)
                .then((response)=>{this.imageName = response.data})
                .catch(() =>{this.imageName = 'unknown filename'})
        }, 
        removeImage: function () { 
            if(this.remove)
                this.remove(this.model)
            this.model = null;
        },
    }, 
    computed:{
        deleteIcon(){
            return require('@/assets/icons/ic_delete.svg')
        },
        imageSource(){
            return `${process.env.VUE_APP_BASE_URI}images/${this.model}`
        },
        model:{
            get(){
                return this.component[this.options.model]
            },
            set(value){
                this.setComponentPropertyValue(value);
            }
        }
    },
    mounted(){
        if(this.model)
            this.getImageName()
            
    }, 
    watch:{
        model: function(){
            this.getImageName()
        }
    }
}
</script>

<style lang="less" scoped>

.image-upload.flex-container{
    align-content: center;
    align-items: center;
    text-overflow: ellipsis; 
    .image-properties{
        flex-direction: column;
    }
}

.delete-icon{
    margin-left: auto;
    margin-right: 16px;
    cursor: pointer;
}


.image-upload{
    width: 264px;
    height: 56px;
    position: relative;
    cursor: pointer;
}

.image{
    max-height: 32px;
    max-width: 40px;
    margin-left: 21px;
    border: 2px solid rgba(0,0,0,0.1);

    width: 100%;
    height: auto;
}
span{
    height: 16px;
    max-height: 16px;
    margin-left: 19px;
    width: 150px;
    max-width: 200px;
    color: rgba(0,0,0,0.6);
    font-family: 'Roboto';
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.4px;
    line-height: 16px;
    text-overflow: ellipsis; 
    overflow: hidden;
}



.upload-btn-wrapper {
    margin: auto;
    position: relative;
    overflow: hidden;
    display: inline-block;

}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
}

.shadow-setter{
    margin-top: 16px;
    img{
        width: 24px;
        height: 24px;
        margin-left: 22px;
        cursor: pointer;
        align-self: center;
    }
    span{
        height: 16px;
        width: 99px;
        color: rgba(0,0,0,0.6);
        font-family: Roboto;
        font-size: 12px;
        font-style: italic;
        letter-spacing: 0.4px;
        line-height: 16px;
        text-align: left;
        margin: auto 8px;
        align-self: center;
    }
}

</style>


