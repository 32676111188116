<template>
    <div class="checkbox-setter flex-container">
        <img :src="checkBoxIcon" @click="checkStatus = !checkStatus">
        <span>{{options.text}}</span>
    </div>
</template>
<script>
import TexasHistoryButton from '@/common/TexasHistoryButton.vue'

export default {
    name:'CheckBox', 
    components:{
       'texas-history-button': TexasHistoryButton, 
    },
    props:{
        component: Object,
        options:Object 
    },
    computed:{
        checkBoxIcon(){
            return this.checkStatus 
                ? require('@/assets/icons/ic_check_box.svg')
                : require('@/assets/icons/ic_check_box_blank.svg')
        },
        checkStatus:{
            get(){
                return this.component[this.options.model]
            }, 
            set(value){
                this.$store.commit('setBuilderComponentPropertyValue',{
                    component: this.component, 
                    propertyName: this.options.model, 
                    value: value});
            }
        }
    }
}
</script>

<style lang="less">

.checkbox-setter{
    margin-top: 16px;
    img{
        width: 24px;
        height: 24px;
        margin-left: 22px;
        cursor: pointer;
        align-self: center;
    }
    span{
	height: 16px;
	width: 99px;
	color: rgba(0,0,0,0.6);
	font-family: Roboto;
	font-size: 12px;
	font-style: italic;
	letter-spacing: 0.4px;
    line-height: 16px;
    text-align: left;
    margin: auto 8px;
    align-self: center;
    text-transform: capitalize;
}

}
</style>


