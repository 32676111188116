import { render, staticRenderFns } from "./ImageDetails.vue?vue&type=template&id=a82ce00e&scoped=true&"
import script from "./ImageDetails.vue?vue&type=script&lang=js&"
export * from "./ImageDetails.vue?vue&type=script&lang=js&"
import style0 from "./ImageDetails.vue?vue&type=style&index=0&id=a82ce00e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a82ce00e",
  null
  
)

component.options.__file = "ImageDetails.vue"
export default component.exports