<template>
    <div class="properties-panel panel flex-container">
        <panel-legend/>
        <language-setter/>
        <div class="properties-container custom-scrollbar">
            <template  v-if="component">
                <component v-for="(options, index) in optionsList" :component="component" 
                :key="'options'+component.id+index" :is="options.name"  :options="options"/>
            </template>
        </div>
        <button class="save-button" :class="{saving: saving}" @click="saveChanges">{{saveButtonText}}</button>
    </div>
</template>
<script>
import PropertiesPanelMixin from '@/components/BackOffice/Mixins/PropertiesPanelMixin.js'
export default {
    name: 'PropertiesPanel', 
    mixins:[PropertiesPanelMixin], 
    props:{
        'save-component':  Function,
    },
    data(){
        return{
            saving: false
        }
    },
    computed:{
        optionsList(){
            return this.component.setters
        },
    }, 
    methods:{
        ToggleAutoSaveInterval(){
            clearInterval(this.autoSaveInterval)
            this.autoSaveInterval =setInterval(function () {
                this.saveChanges()
            }.bind(this), 15000); 
        }, 
        clearAutoSaveInterval(){
            this.saveChanges()
            clearInterval(this.autoSaveInterval)
        }, 
    },
}
</script>

