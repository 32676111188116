<template>
    <div class="color-setter">
        <div class="properties-section flex-container">
            <div class="property-control">
                <select v-model="selectedValue">
                    <option v-for="option in alignmentOptions" :key="option.text"
                         :value="option.value">{{option.text}}</option>
                </select>
                <label for="image-width">{{$t('textAlignmentTag')}}</label>
            </div>
        </div>
    </div>
</template>
<script>
import ComponentSetterMixin from '@/components/BackOffice/Mixins/ComponentSetterMixin.js'
export default {
    name:'TextAlignmentSetter', 
    mixins:[ComponentSetterMixin],  
    data: function() {
        return{
            selected: '', 
            alignmentOptions:[
                    {text: this.$t('start'), value:'flex-start'},
                    {text: this.$t('center'), value:'center'},]
        }
    },
    computed:{
        selectedValue:{
            get(){
                return this.component.alignment
            }, 
            set(value){
                this.setComponentPropertyValue(value, 'alignment')
            }
        }
    }
}
</script>

